import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "../styles/styles.scss"
import footerStyles from "./footer.module.scss"

import { FontAwesomeIcon } from '../../node_modules/@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope, faAt } from '../../node_modules/@fortawesome/free-solid-svg-icons'

const Footer = () => {
  const data = useStaticQuery(graphql`
      query {
          site {
              siteMetadata {
                  author
              }
          }
      }
  `)

  return (
      <footer className={footerStyles.footer}>
        <Container fluid className={`p-0 ${footerStyles.footerContainer}`}>
          <Row className={footerStyles.footerRow}>
            <Col>
              <p>
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
              </p>
              <p>Przyłęk 137</p>
              <p>64-300 Nowy Tomyśl</p>
            </Col>
            <Col>
              <p>
                <FontAwesomeIcon icon={faPhoneAlt}/>
              </p>
              <p>+48 601 709 371</p>
              <p>+48 667 719 372</p>
            </Col>
            <Col>
              <p>
                <FontAwesomeIcon icon={faEnvelope}/>
              </p>
              <p>wierzba32<FontAwesomeIcon icon={faAt}/>wp.pl</p>
              <p>www.uwierzby.pl</p>
            </Col>
          </Row>
          <Row className={footerStyles.footerRow}>
            <Col md={3}>
                <Link
                  className={footerStyles.footerLink}
                  activeClassName={footerStyles.activeFooterLink}
                  to="/polityka-prywatnosci/"
                >
                  Polityka Prywatności
                </Link>
            </Col>
            <Col md={6}>
                <p>Pensjonat U Wierzby Pod Lasem
                <span>© {(new Date().getFullYear())} - all rights reserved </span></p>
            </Col>
            <Col md={3}>
              <p>
                <span>Realizacja:</span> 
                <a
                    className={footerStyles.footerLink}
                    href="https://www.everes.studio/"
                    rel="noreferrer"
                    target="_blank"
                  >
                {data.site.siteMetadata.author}	&reg;
                </a>
              </p>
            </Col>
          </Row>
        </Container> 
      </footer>
  )
}

export default Footer
