import React, {Component} from "react";
import {Link} from "gatsby"
import "../styles/styles.scss"
import headerStyles from "./header.module.scss"
import logoCircle from "../images/logo-wierzba-small-brown.png"
import {FontAwesomeIcon} from '../../node_modules/@fortawesome/react-fontawesome'
import {faPhoneAlt} from '../../node_modules/@fortawesome/free-solid-svg-icons'


export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            scrollPos: 0,
            addClass: false
        };
        this.handleScroll = this
            .handleScroll
            .bind(this); 
        this.toggleClass = this
            .toggleClass
            .bind(this);     
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    };
    
    // Hide or show the menu.
    handleScroll() {
        const {scrollPos} = this.state;
        this.setState({
            show: document
                .body
                .getBoundingClientRect()
                .top === scrollPos
        });
    }
    //Hamburger menu
    toggleClass() {
        this.setState({
            addClass: !this.state.addClass
        })
    }
    render() {
        let buttonClass = ["hamburger"];
        let header = [`${headerStyles.header}`]
        let navigationBackground = [`${headerStyles.navigationBackground}`];
        let navigationWrapper = [`${headerStyles.navigationWrapper}`];
        if(this.state.addClass) {
            buttonClass.push('is-active');
            navigationBackground.push(`${headerStyles.isActive}`);
            navigationWrapper.push(`${headerStyles.isActive}`);
        }

        return (

            <header
                className={`${this.state.show
                ? headerStyles.header
                : headerStyles.header + ' ' + headerStyles.scrolled} ${this.state.addClass ? headerStyles.isActive : ''}`}>
                <button className={`hamburger hamburger--squeeze ${headerStyles.hamburgerMenuBtn} ${this.state.addClass ? 'is-active': ''}`} onClick={this.toggleClass} type="button" aria-label="Menu" aria-controls="navigation">
                    <span className="hamburger-box">
                        <span className={`hamburger-inner ${headerStyles.hamburgerInner}`}></span>
                    </span>
                </button>
                <nav id="navigation"
                    className={`${this.state.show
                    ? headerStyles.nav
                    : headerStyles.nav + ' ' + headerStyles.scrolled} ${this.state.addClass ? headerStyles.isActive : ''}`}>

                    <div className={`${headerStyles.navigationBackground} ${this.state.addClass ? headerStyles.isActive : ''}`}></div>
                    
                    <div className={`${headerStyles.navigationWrapper} ${this.state.addClass ? headerStyles.isActive : ''}`}>
                        <div
                            className={this.state.show
                            ? headerStyles.logoCircleWrapper
                            : headerStyles.logoCircleWrapper + ' ' + headerStyles.scrolled}>
                            <img className={headerStyles.logoCircle} src={logoCircle} alt="logo circle"></img>
                        </div>
                        <ul
                            className={this.state.show
                            ? headerStyles.navList
                            : headerStyles.navList + ' ' + headerStyles.scrolled}>
                            <li>
                                <Link
                                    className={headerStyles.navItem}
                                    activeClassName={headerStyles.activeNavItem}
                                    to="/">
                                    start
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={headerStyles.navItem}
                                    activeClassName={headerStyles.activeNavItem}
                                    to="/o-nas/">
                                    o nas
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={headerStyles.navItem}
                                    activeClassName={headerStyles.activeNavItem}
                                    to="/oferta/">
                                    oferta
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={headerStyles.navItem}
                                    activeClassName={headerStyles.activeNavItem}
                                    to="/pokoje/">
                                    pokoje
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={headerStyles.navItem}
                                    activeClassName={headerStyles.activeNavItem}
                                    to="/galeria/">
                                    galeria
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={headerStyles.navItem}
                                    activeClassName={headerStyles.activeNavItem}
                                    to="/atrakcje/">
                                    atrakcje
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={headerStyles.navItem}
                                    activeClassName={headerStyles.activeNavItem}
                                    to="/kontakt/">
                                    kontakt
                                </Link>
                            </li>
                        </ul>
                        <div
                            className={this.state.show
                            ? headerStyles.phoneWrapper
                            : headerStyles.phoneWrapper + ' ' + headerStyles.scrolled}>
                            <p>
                                <FontAwesomeIcon icon={faPhoneAlt}/>
                            </p>
                            <p>
                                <a href="tel:+48 601 709 371">+48 601 709 371</a>
                            </p>
                        </div>
                    </div>
                </nav>

            </header>
        );
    }
}
