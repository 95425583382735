/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "../styles/styles.scss"
import layoutStyles from "./layout.module.scss"

import Header from "./header"
import Footer from "./footer"
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {
  return (
      <div className={layoutStyles.container}>
        <div className={layoutStyles.content}>
          <Header/>
          <main>{children}</main>
          <CookieConsent
            location="bottom"
            buttonText="OK rozumiem"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#65443D" }}
            buttonStyle={{ background: "#ffffff", color: "#65443D", fontSize: "16px" }}
            expires={150}
          >
          Ta strona używa COOKIES. Korzystając z niej wyrażasz zgodę na wykorzystywanie cookies, zgodnie z ustawieniami Twojej przeglądarki.{" "}
            <span style={{ fontSize: "18px"}}><a className={layoutStyles.cookieLink} href="../polityka-prywatnosci/">Polityka prywatności</a></span>
          </CookieConsent>
        </div>
        <Footer />
      </div>
  )
}



export default Layout
